import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Hotjar from "@hotjar/browser";
import { Helmet } from "react-helmet";

import AuthRoute from "./routes/AuthRoute";
import ProtectedRoute from "./routes/ProtectedRoute";
import ProtectedRouteSubscribed from "./routes/ProtectedRouteSubscribed";

import {
  Homepage,
  Login,
  SignUp,
  Subscription,
  SubscriptionExpired,
  PendingConfirmation,
  EmailConfirmation,
  OpenStrategies,
  ResetPassword,
  ResendConfirmationEmail,
  UserConfig,
  LandingPage,
  TelegramInfo,
  AboutUs,
  Checkout,
  Ranking,
} from "./pages";

const App = () => {
  const siteId = 3897083;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            index
            element={
              <>
                <Helmet>
                  <title>
                    Orion Sinais: Receba sinais de compra e venda de
                    criptoativos em tempo real
                  </title>
                </Helmet>
                <LandingPage />
              </>
            }
          />
          <Route
            path="/reset_password"
            element={
              <>
                <Helmet>
                  <title>Redefinir a senha • Orion Sinais</title>
                </Helmet>
                <ResetPassword />
              </>
            }
          />
          <Route
            path="/lp"
            element={
              <>
                <Helmet>
                  <title>
                    Orion Sinais: Receba sinais de compra e venda de
                    criptoativos em tempo real
                  </title>
                </Helmet>
                <LandingPage />
              </>
            }
          />
          <Route
            path="/sobre-nos"
            element={
              <>
                <Helmet>
                  <title>Sobre nós • Orion Sinais</title>
                </Helmet>
                <AboutUs />
              </>
            }
          />
          <Route
            path="/resend_confirmation_email"
            element={
              <>
                <Helmet>
                  <title>Confirmar E-mail • Orion Sinais</title>
                </Helmet>
                <ResendConfirmationEmail />
              </>
            }
          />
          <Route
            path="/dashboard/:coinPair?"
            element={
              <ProtectedRouteSubscribed>
                <Helmet>
                  <title>Dashboard • Orion Sinais</title>
                </Helmet>
                <Homepage />
              </ProtectedRouteSubscribed>
            }
          />
          <Route
            path="/telegram-info"
            element={
              <ProtectedRouteSubscribed>
                <Helmet>
                  <title>Conheça nosso Telegram • Orion Sinais</title>
                </Helmet>
                <TelegramInfo />
              </ProtectedRouteSubscribed>
            }
          />
          <Route
            path="/configuracoes"
            element={
              <ProtectedRouteSubscribed>
                <Helmet>
                  <title>Configurações • Orion Sinais</title>
                </Helmet>
                <UserConfig />
              </ProtectedRouteSubscribed>
            }
          />
          <Route
            path="/login"
            element={
              <AuthRoute>
                <Helmet>
                  <title>Login • Orion Sinais</title>
                </Helmet>
                <Login />
              </AuthRoute>
            }
          />
          <Route
            path="/cadastro"
            element={
              <AuthRoute>
                <Helmet>
                  <title>Cadastro • Orion Sinais</title>
                </Helmet>
                <SignUp />
              </AuthRoute>
            }
          />
          <Route
            path="/assinatura"
            element={
              <ProtectedRoute>
                <Helmet>
                  <title>Assinatura • Orion Sinais</title>
                </Helmet>
                <Subscription />
              </ProtectedRoute>
            }
          />
          <Route
            path="/assinatura-terminada"
            element={
              <ProtectedRoute>
                <Helmet>
                  <title>Assinatura expirada • Orion Sinais</title>
                </Helmet>
                <SubscriptionExpired />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sinais-abertos"
            element={
              <ProtectedRouteSubscribed>
                <Helmet>
                  <title>Sinais em Aberto • Orion Sinais</title>
                </Helmet>
                <OpenStrategies />
              </ProtectedRouteSubscribed>
            }
          />
          <Route
            path="/ranking"
            element={
              <ProtectedRouteSubscribed>
                <Helmet>
                  <title>Ranking • Orion Sinais</title>
                </Helmet>
                <Ranking />
              </ProtectedRouteSubscribed>
            }
          />
          <Route
            path="/confirmacao-pendente"
            element={
              <>
                <Helmet>
                  <title>Confirmação pendente • Orion Sinais</title>
                </Helmet>
                <PendingConfirmation />
              </>
            }
          />
          <Route
            path="/confirm_account"
            element={
              <>
                <Helmet>
                  <title>Conta validada • Orion Sinais</title>
                </Helmet>
                <EmailConfirmation />
              </>
            }
          />
          <Route
            path="/checkout/:state"
            element={
              <ProtectedRoute>
                <Helmet>
                  <title>Checkout • Orion Sinais</title>
                </Helmet>
                <Checkout />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
