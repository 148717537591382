import React from "react";
import useAuth from "../hooks/useAuth";
import useUser from "../hooks/useUser";
import { Navigate } from "react-router-dom";
import { LoadingScreen } from "../pages";

const AuthRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { isSubscribed, loadingUser } = useUser();

  if (loadingUser) return <LoadingScreen />;

  if (isAuthenticated() && isSubscribed()) {
    return <Navigate to="/dashboard" />;
  }

  if (isAuthenticated() && !isSubscribed()) {
    return <Navigate to="/assinatura" />;
  }

  return children;
};

export default AuthRoute;
