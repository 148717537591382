import { useState, useEffect } from "react";
import orionAPI from "../config/orionAPI";

const useRanking = () => {
  const [ranking, setRanking] = useState([]);
  const [loadingRanking, setLoadingRanking] = useState(true);

  const fetchRanking = async () => {
    setLoadingRanking(true);
    try {
      const response = await orionAPI.get("/api/ranking");
      setRanking(response.data || []);
    } catch (error) {
      console.error(error);
      setRanking([]);
    } finally {
      setLoadingRanking(false);
    }
  };

  useEffect(() => {
    fetchRanking();
  }, []);

  return { ranking, loadingRanking };
};

export default useRanking;
