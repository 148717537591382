import React from "react";
import "./index.css";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

function Tooltip({
  text,
  children,
  icon: Icon = QuestionMarkCircleIcon,
  className = "",
}) {
  const content = children || text;

  return (
    <div className={`orion__tooltip ${className}`}>
      <Icon className="orion__tooltip-icon" />
      <div className="orion__tooltip-bubble">{content}</div>
    </div>
  );
}

export default Tooltip;
