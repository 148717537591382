import React, { useState, useEffect } from "react";

import orionAPI from "../config/orionAPI";

const useOpenStrategies = () => {
  const [openStrategies, setOpenStrategies] = useState([]);
  const [loadingOpenStrategies, setLoadingOpenStrategies] = useState(true);

  const fetchOpenStrategies = async () => {
    setLoadingOpenStrategies(true);
    await orionAPI
      .get("/api/dashboard/position_trade/open_trades")
      .then((response) => {
        setOpenStrategies(response.data);
        setLoadingOpenStrategies(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchOpenStrategies();
  }, []);

  return { openStrategies, loadingOpenStrategies };
};

export default useOpenStrategies;
