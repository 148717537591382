import React from "react";
import "./pairTierIcon.css";

function PairTierIcon({ entriesTotal, position, tier }) {
  const getTierEmoji = (tier) => {
    if (position >= 1 && position <= 5) {
      switch (position) {
        case 1:
          return "🥇";
        case 2:
          return "🥈";
        case 3:
          return "🥉";
        default:
          return "🏅";
      }
    }
    if (position >= entriesTotal - 4 && position <= entriesTotal) {
      return "☠️";
    }
    switch (tier) {
      case "good":
        return "✅";
      case "average":
        return "⚠️";
      case "bad":
        return "❌";
      default:
        return "❓";
    }
  };

  return (
    <div className="orion__tier-icon-container">
      <span className="orion__tier-icon">{getTierEmoji(tier)}</span>
    </div>
  );
}

export default PairTierIcon;
